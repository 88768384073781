import React, { useEffect } from 'react';
import { navigate } from 'gatsby';

import Layout from '../layouts';
import PageTitle from '../components/Subpage/title';
import QuoteSummary from '../components/Quote/summary';

const page = { name: 'Your Quote', slug: 'your-quote' };

export default function YourQuotePage({ location }) {
  const dataFound =
    typeof window !== `undefined` && window.history.state !== null;

  const prevPage = location.state && location.state.prevPage;
  const summary = dataFound && window.history.state.summary;

  useEffect(() => {
    if (!summary) {
      navigate('/get-quote');
    }
  }, []);

  return (
    <Layout
      title={page.name}
      location={`/${page.slug}/`}
      padding="0"
      prevPage={prevPage}
    >
      <article>
        <PageTitle title={page.name} margin="0" />
        <QuoteSummary summary={summary} />
      </article>
    </Layout>
  );
}
