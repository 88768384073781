import React, { useContext, useEffect, useState } from 'react';
import { navigate } from 'gatsby';
import { v4 as uuid } from 'uuid';
import parse from 'html-react-parser';

import CartContext from '../Cart/context';
import { add } from '../Cart/storage';

import Container from '../Container';
import Row from '../Container/row';
import Col from '../Container/column';
import TitleH2 from '../Title/h2';
import ButtonInput from '../Button/input';
import { AuthUserContext } from '../Session';
import { calculateVat } from '../Checkout/calculations';

import { BorderRadius, DarkGrey, TitleColour } from '../../utils/variables';

import './style.css';
import './summary.css';

export default function QuoteSummary({ summary }) {
  const { price, data } = summary;

  const [details, setDetails] = useState({});
  const authUser = useContext(AuthUserContext);

  const capitalize = val => {
    return val.charAt(0).toUpperCase() + val.slice(1);
  };

  const formatVal = val => {
    const newVal = val?.replaceAll('-', ' ')?.replaceAll('_', ' ');
    return typeof val === 'string' ? parse(capitalize(newVal)) : val;
  };

  const getOptionLabel = (id, type, option) => {
    const { style, colours, handle_colours, options } = summary.options;

    if (typeof option === 'boolean') {
      return option ? 'Yes' : 'No';
    }

    if (type === 'style') {
      return style?.filter(x => x.id === id)[0]?.label;
    }

    if (type === 'colour') {
      return colours?.filter(x => x.id === id)[0]?.label;
    }

    if (type === 'handle') {
      return handle_colours?.filter(x => x.id === id)[0]?.label;
    }

    if (type === 'options') {
      const activeOption = options?.filter(x => x.id === id)[0]?.options;
      return activeOption?.filter(y => y.id === option)[0]?.label;
    }

    return ``;
  };

  useEffect(() => {
    const {
      reference,
      product,
      height,
      width,
      style,
      doors,
      interior_colour,
      exterior_colour,
      interior_colour_code,
      exterior_colour_code,
      handle_colour,
      handle_colour_code,
      databaseId,
      ...other
    } = summary.data;

    const quoteOptions = {};
    Object.keys(other).forEach(key => {
      quoteOptions[key] = getOptionLabel(key, 'options', other[key]);
    });

    const quoteSummary = {
      reference,
      type: product,
      dimensions: `W:${width}mm x H:${height}mm`,
      style: `${getOptionLabel(style, 'style')}${
        doors ? ` opening ${doors}` : ``
      }`,
      colour: `Interior: ${
        interior_colour === 'ral-custom'
          ? `RAL: ${interior_colour_code}`
          : getOptionLabel(interior_colour, 'colour')
      }<br />Exterior: ${
        exterior_colour === 'ral-custom'
          ? `RAL: ${exterior_colour_code}`
          : getOptionLabel(exterior_colour, 'colour')
      }<br />Handles: ${
        handle_colour === 'ral-custom'
          ? `RAL: ${handle_colour_code}`
          : getOptionLabel(handle_colour, 'handle')
      }`,
      options: quoteOptions,
    };

    setDetails(quoteSummary);
  }, []);

  const { updateTotal } = useContext(CartContext);

  const addProduct = () => {
    const product = { ...data, price, id: uuid() };
    add(product);
    updateTotal();
  };

  const checkoutQuote = () => {
    addProduct();
    navigate('/checkout');
  };

  const addQuote = () => {
    addProduct();
    navigate('/get-quote');
  };

  const amendQuote = () => {
    navigate('/get-quote', {
      state: {
        edit: data,
      },
    });
  };

  const priceVat = calculateVat(price);
  const priceIncVat = price + priceVat;

  const isAuthUser = authUser !== null;

  return (
    <div className="quoteInner">
      <Container>
        <Row justify="space-evenly">
          <Col
            className="summaryActions"
            xxl={{ width: `45%` }}
            xl={{ width: `50%` }}
            lg={{ width: `55%`, order: 2 }}
          >
            <div style={leftCol}>
              <TitleH2 margin="15px" style={headerStyle}>
                Your <span>Quote</span>
              </TitleH2>
              {isAuthUser && <p style={textStyle}>Price excluding VAT</p>}
              <strong style={priceStyle}>&pound;{price?.toFixed(2)}</strong>
              {isAuthUser && (
                <>
                  <p style={textStyle}>Price including VAT</p>
                  <strong style={priceStyle}>
                    &pound;{priceIncVat?.toFixed(2)}
                  </strong>
                </>
              )}
              <div style={quoteActions}>
                <ButtonInput
                  value="Order & Pay"
                  onClick={() => checkoutQuote()}
                  style={buttonStyle}
                  primary
                />
                <ButtonInput
                  value="Add Another"
                  onClick={() => addQuote()}
                  style={buttonStyle}
                  primary
                  dark
                />
              </div>
            </div>
          </Col>
          <Col
            className="quoteSummary"
            // w={{ width: `20%` }}
            xxl={{ width: `25%` }}
            xl={{ width: `30%` }}
            lg={{ width: `35%`, order: 1 }}
          >
            <TitleH2 margin="30px" style={headerStyle}>
              Quote <span>Summary</span>
            </TitleH2>
            <ul>
              {Object.keys(details).map(key => {
                const label = capitalize(key);
                const val = details[key];
                return (
                  val &&
                  val !== '' &&
                  val !== undefined && (
                    <li key={key}>
                      <strong style={{ color: TitleColour }}>{label}</strong>
                      {typeof val === 'string' && <p>{formatVal(val)}</p>}
                      {typeof val === 'object' && (
                        <p>
                          {Object.keys(val).map(x => {
                            return (
                              val[x] &&
                              val[x] !== '' &&
                              val[x] !== undefined && (
                                <>
                                  <span key={x}>
                                    {capitalize(x.replaceAll('_', ' '))}:{' '}
                                    {formatVal(val[x])}
                                  </span>
                                  <br />
                                </>
                              )
                            );
                          })}
                        </p>
                      )}
                    </li>
                  )
                );
              })}
            </ul>
            <ButtonInput
              value="Amend Quote"
              onClick={() => amendQuote()}
              style={buttonStyle}
              secondary
              dark
            />
          </Col>
        </Row>
      </Container>
    </div>
  );
}

const leftCol = {
  backgroundColor: `#f9f9f9`,
  borderRadius: BorderRadius,
  border: `1px solid rgba(0,0,0,0.2)`,
  padding: `45px`,
  textAlign: `center`,
  height: `100%`,
  display: `flex`,
  flexDirection: `column`,
  alignItems: `center`,
  justifyContent: `center`,
};

const headerStyle = {
  textTransform: `uppercase`,
};

const priceStyle = {
  color: DarkGrey,
  // fontSize: `60px`,
  lineHeight: 1,
  marginBottom: `30px`,
  display: `block`,
};

const textStyle = {
  marginBottom: `15px`,
};

const quoteActions = {
  marginTop: `15px`,
};

const buttonStyle = {
  width: `300px`,
  maxWidth: `100%`,
  marginTop: `15px`,
};
